<template>
  <div>
    <div class="backgroundOV"></div>
    <v-card outlined>
      <v-card-title class="titleLarge">Einmaleins</v-card-title>
    </v-card>
    <br />
    <br />
    <v-card outlined>
      <v-card-title class="titleSmaller"
        >Was erwartet Sie im Inhaltsbereich Einmaleins?</v-card-title
      >
      <v-card-text>
        Drei wesentliche Aspekte gelten weithin als Grundlage der sicheren
        Beherrschung des und zur Ausbildung eines umfassenden Verständnisses für
        das Einmaleins.
        <ol>
          <li>Kenntnis der Kernaufgaben</li>
          <li>Nutzung von Ableitungsstrategien</li>
          <li>Vernetzung von Aufgaben und Darstellungen</li>
        </ol>
        Im Anschluss an die folgenden Ausführungen werden fallbasierte Aufgaben
        zur Diagnose und zur Förderung gestellt, sodass Sie die Aspekte des
        Hintergrundwissens anwenden können.
        <br />
        Den Abschluss bildet eine Kompetenzübersicht, sodass transparent werden
        kann, was im Inhaltsbereich Einmaleins von zentraler Bedeutung ist.
      </v-card-text>
    </v-card>

    <v-row no-gutters justify="center" align="center">
      <p class="white--text" style="z-index: 1; padding-top: 15px">
        Im unteren Bereich finden Sie die Navigation.
      </p>
    </v-row>
    <v-row no-gutters justify="center" align="center">
      <v-icon x-large class="white--text">mdi-arrow-down</v-icon>
    </v-row>
    <AppBottomNavEME next="/einmaleins/hintergrundwissen" />
  </div>
</template>

<script>
import AppBottomNavEME from "@/common/AppBottomNavEME";
export default {
  components: {
    AppBottomNavEME,
  },
};
</script>
<style scoped>
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}
#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
</style>
